import { http } from '@/core/api/';
import codeDocumentTypes from '@/utils/constants/documentTypes';
import fileInputStatus from '@/utils/constants/fileInputStatus';

const apiUrl = 'api/pei';

export default {
  generalPeiAtInformation(peiId) {
    return http.get(`api/atservice/getpeiatservice?peiId=${peiId}`);
  },

  generalPeiCodeAt() {
    return http.get(`api/atservice/getpeiatcode`);
  },

  documentsUploadFiles(files) {
    return http.post(`api/atservice/uploadatdocument`, files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  downloadDocument(documentFile) {
    const byteChars = atob(documentFile.content);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray], {
      type: documentFile.contentType,
    });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = documentFile.filename;
    link.click();
  },
  documentsDownloadFiles(peiAtServiceId, codeDocumentType) {
    http
      .get(
        `api/atservice/downloadatdocument?peiAtServiceId=${peiAtServiceId}&codeDocumentType=${codeDocumentType}`
      )
      .then((response) => {
        this.downloadDocument(response.data);
      });
  },
  downloadStudentDocument(evaluationId, documentId) {
    return new Promise((resolve, reject) => {
      http
        .get(
          `api/studentevaluation/${evaluationId}/downloadDocuments/${documentId}`
        )
        .then((response) => {
          if (response && response.data) {
            this.downloadDocument(response.data);
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  documentsPeiDownloadFiles(id) {
    http.get(`api/pei/downloaddocument?id=${id}`).then((response) => {
      this.downloadDocument(response.data);
    });
  },
  documentDownloadLocalFile(file) {
    let fr = new FileReader();
    fr.readAsDataURL(file);
    let blob = new Blob([file], { type: 'application/pdf' });

    let objectURL = window.URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.href = objectURL;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
  documentDownloadReport(content, filename) {
    let binaryString = window.atob(content);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    let blob = new Blob([bytes], { type: 'application/pdf' });

    let objectURL = window.URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.href = objectURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
  documentsDeleteFiles(peiAtServiceId) {
    return http.get(
      `api/atservice/deleteatdocument?peiAtServiceId=${peiAtServiceId}`
    );
  },
  documentsLoadFiles(peiAtServiceId) {
    return http.get(
      `api/atservice/loadatdocument?peiAtServiceId=${peiAtServiceId}`
    );
  },
  async evaluationDownloadFiles(
    studentEvaluationId,
    studentEvaluationsDocumentIds
  ) {
    return http
      .get(
        `/api/studentevaluation/${studentEvaluationId}/documents/${studentEvaluationsDocumentIds}`,
        { responseType: 'blob' }
      )
      .then((response) => {
        let blob = new Blob([response.data], { type: 'application/pdf' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Documento Adjunto.pdf';
        link.click();
      })
      .catch((e) => {
        return e;
      });
  },
  async downloadDocumentByAttachmentId(
    studentEvaluationsDocumentIds,
    studentEvaluationsAttachmentIds
  ) {
    let documentId = null;
    if (studentEvaluationsDocumentIds != '') {
      documentId = studentEvaluationsDocumentIds;
    }
    let attachmentId = null;
    if (studentEvaluationsAttachmentIds != '') {
      attachmentId = studentEvaluationsAttachmentIds;
    }
    return http
      .get(`/api/documents/getdocument/${documentId}/${attachmentId}/2`)
      .then((response) => {
        this.downloadDocument(response.data);
      })
      .catch((e) => {
        return e;
      });
  },
  // Download document according to attachment id and category.
  async downloadDocumentByAttachmentIdByDocumentCategory(
    attachmentId,
    documentCategory
  ) {
    return http
      .get(
        `/api/documents/documentbycategory/${attachmentId}/${documentCategory}`
      )
      .then((response) => {
        this.downloadDocument(response.data);
      })
      .catch((e) => {
        return e;
      });
  },
  async downloadDocumentForThrerapyReferral(attachmentId) {
    return http
      .get(`/api/documents/downloadtherapyreferral/${attachmentId}`)
      .then((response) => {
        this.downloadDocument(response.data);
      })
      .catch((e) => {
        return e;
      });
  },
  async getDocument(id) {
    return http
      .get(`/api/documents/${id}`)
      .then((response) => {
        this.downloadDocument(response.data);
      })
      .catch((e) => {
        throw e;
      });
  },
  async downloadDocumentForRP(attachmentId) {
    return http
      .get(`/api/documents/downloadrp/${attachmentId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: 'application/pdf' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Documento Adjunto.pdf';
        link.click();
      })
      .catch((e) => {
        return e;
      });
  },
  deleteDocumentByAttachmentId(attachmentId) {
    return http.delete(`/api/pei/deletedocument?id=${attachmentId}`);
  },
  async uploadRejectedLocationDocument(document) {
    let hasDocumentToUpload =
      document.status == fileInputStatus.Loaded &&
      document.file != null &&
      document.date != null;
    if (document.status == fileInputStatus.Deleted) {
      await http.delete(`/api/pei/deletedocument?id=${document.attachmentId}`);
    } else if (hasDocumentToUpload) {
      let formData = new FormData();

      formData.append('date', document.date);
      formData.append('file', document.file);
      formData.append(
        'documentTypeId',
        codeDocumentTypes.LocationRejectedDocument
      );
      formData.append('id', document.id);
      if (document.attachmentId)
        formData.append('itemAttachmentId', document.attachmentId);

      await http.post('/api/pei/uploaddocument', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }
  },
  async uploadDocument(document) {
    let hasDocumentToUpload =
      document.status == fileInputStatus.Loaded &&
      document.file != null &&
      document.date != null;
    if (document.status == fileInputStatus.Deleted) {
      await http.delete(`/api/pei/deletedocument?id=${document.attachmentId}`);
    } else if (hasDocumentToUpload) {
      let formData = new FormData();

      formData.append('date', document.date);
      formData.append('file', document.file);
      formData.append('documentTypeId', document.documentType);
      formData.append('id', document.id);
      if (document.attachmentId)
        formData.append('itemAttachmentId', document.attachmentId);

      await http.post('/api/pei/uploaddocument', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }
  },
  async getAmendmentSections(peiId) {
    const response = await http.get(
      `/api/pei/getsectionsamendment?peiId=${peiId}`
    );
    return response.data;
  },
  async saveAdmendmentSections(admenment) {
    return await http.post('/api/pei/addpeievaluationamendment', admenment, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  async changeStatus(peiId, statusId) {
    await http.post('/api/pei/changeStatus', {
      peiid: peiId,
      statusId: parseInt(statusId),
    });
  },
  async signPei(peiSign) {
    await http.post('/api/pei/signpei', {
      peiId: peiSign.peiId,
      signedDate: peiSign.signedDate,
      studentTardinessLog: peiSign.studentTardinessLog,
      hasRequiredFuturePeiCopy: peiSign.hasRequiredFuturePeiCopy,
    });
  },
  async getPeiSignDetail(peiId) {
    return await http.get(`/api/pei/${peiId}/signdetail`);
  },
  async getServiceModalityTypes(peiId, isTherapy, isTypeOther) {
    isTherapy = isTherapy === true;
    isTypeOther = isTypeOther === true;

    return await http.get(
      `${apiUrl}/modalitytypes?peiId=${peiId}&isTherapy=${isTherapy}&isTypeOther=${isTypeOther}`
    );
  },
  async getDurations(peiId, modalityId, isTherapy) {
    isTherapy = isTherapy === true;
    modalityId = modalityId > 0 ? modalityId : 0;

    return await http.get(
      `${apiUrl}/durations?peiId=${peiId}&modalityId=${modalityId}&isTherapy=${isTherapy}`
    );
  },
  async getReferralDocuments(analysisServiceId) {
    return await http.get(`${apiUrl}/referraldocuments/${analysisServiceId}`);
  },
  async getReferralDocumentsByStudentServiceId(studentServiceId, peiId) {
    return await http.get(
      `${apiUrl}/getallreferralitemattachment/${studentServiceId}/${peiId}`
    );
  },
  async getAllPeiServiceNeed(peiId) {
    return await http.get(`${apiUrl}/AllPeiServiceNeed?peiId=${peiId}`);
  },
};
