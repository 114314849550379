const peiStatuses = {
  Borrador: '1',
  Cancelado: '2',
  Completado: '3',
  Firmado: '280',
  Incompleto: '737',
  CompletedWithPendingFunds: '873',
  PartialSignedPendingFunds: '874',
  SignedWithControversy: '1879',
  ProposalRejected: '1889',
};

export default peiStatuses;
