<template>
  <div v-if="studentSieId">
    <page-header
      has-return
      :page="{
        icon: 'fas fa-user-edit',
        title: 'Historial del PEI',
        routes: ['Inicio', 'Historial del PEI'],
      }"
    />

    <student-pei-history :student-sie-id="studentSieId" />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import StudentPeiHistory from '@/components/StudentPeiHistory';

export default {
  name: 'PeiHistory',
  components: { PageHeader, StudentPeiHistory },
  props: { studentSieId: { type: [String, Number], default: null } },
};
</script>
