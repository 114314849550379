<template>
  <div class="timeline__container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiTimeLine',
};
</script>

<style>
.timeline__container .timeline__item:first-child .timeline__content::before {
  height: calc(100% - 35px + 20px);
  top: 35px;
}

.timeline__container .timeline__item:last-child .timeline__content::before {
  height: 35px;
}

@media screen and (max-width: 411px) {
  .timeline__container {
    margin-right: 37%;
  }
}

@media screen and (max-width: 375px) {
  .timeline__container {
    width: 60%;
  }
}

@media screen and (max-width: 360px) {
  .timeline__container {
    width: 58%;
  }
}
</style>
