<template>
  <div class="d-flex timeline__item">
    <div class="mr-3 mt-4">
      <div class="w-date-fixed">
        <slot name="date" />
      </div>
    </div>
    <div class="timeline__content w-100">
      <div class="timeline__content--inner p-4">
        <div v-if="icon || $slots['icon']" class="icon-absolute">
          <slot v-if="$slots['icon']" name="icon" />
          <em v-else class="radius text-white" :class="icon" />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTimeLineItem',
  props: {
    icon: {
      type: String,
      default: () => null,
    },
  },
};
</script>

<style>
.timeline__content {
  margin-bottom: 20px;
  margin-left: 40px;
  position: relative;
}

.timeline__content .timeline__content--inner {
  background: #f7f7f7;
  border-radius: 3px;
  position: relative;
}

.icon-absolute {
  position: absolute;
  left: -1.3%;
}

.radius {
  padding: 8px;
  padding-top: 9px;
  width: 36px;
  height: 36px;
  text-align: center;
  background-color: #b5c3bc;
  border-radius: 50%;
}

.timeline__content:before {
  background: #e2e2e2;
  border-radius: 2px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  content: '';
  height: calc(100% + 20px);
  left: -30px;
  position: absolute;
  width: 1px;
}

.timeline__content:after {
  background: #707070;
  border: 2px solid #707070;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  content: '';
  height: 10px;
  left: -34px;
  position: absolute;
  top: 35px;
  width: 10px;
}

.w-date-fixed {
  width: 90px;
}

@media screen and (max-width: 1055px) {
  .icon-absolute {
    left: -2%;
  }
}

@media screen and (max-width: 780px) {
  .icon-absolute {
    left: -3%;
  }
}

@media screen and (max-width: 670px) {
  .icon-absolute {
    left: -3.3%;
  }
}

@media screen and (max-width: 411px) {
  .icon-absolute {
    left: -6% !important;
  }
}
</style>
