<template>
  <div class="rounded p-2">
    <div class="rounded p-3">
      <icon-view
        class="mt-1"
        icon="fas fa-comment-alt mb-1"
        icon-background="#ff745c"
        icon-color="#fff"
        title="Comentario al PEI"
      />
      <validation-observer ref="addCommentRef" novalidate>
        <div class="row my-3">
          <div class="col-md-4">
            <validation-provider v-slot="{ errors }" rules="required">
              <custom-drop-down
                id="add-comment-pei-part"
                v-model="comment.peiPart"
                :clearable="false"
                custom-error-msg="La parte del PEI es requerida."
                :error="errors[0]"
                fieldkey="id"
                fieldname="name"
                label="Parte del PEI:"
                :options="availablePeiParts"
                placeholder="Seleccione"
                @input="onPeiPartChange"
              />
            </validation-provider>
          </div>
          <div v-if="availableCategories.length" class="col-md-4">
            <validation-provider v-slot="{ errors }" rules="required">
              <custom-drop-down
                id="add-comment-category"
                v-model="comment.subSection"
                :clearable="false"
                custom-error-msg="La categoría de la parte del PEI es requerida."
                :error="errors[0]"
                fieldkey="id"
                fieldname="name"
                label="Categoría:"
                :options="availableCategories"
                placeholder="Seleccione"
                @input="onCategoryChange"
              />
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <validation-provider v-slot="{ errors }" rules="required">
              <custom-text-area
                id="add-comment-text-id"
                v-model="comment.description"
                class="color-gray-6"
                custom-error-msg="El comentario es requerido."
                :error="errors[0]"
                label="Comentario:"
                :maxlength="3000"
                show-char-count
              />
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div
            class="
              col-12 col-md-2
              offset-md-10
              pl-3
              pr-2
              d-flex
              align-items-end
              justify-content-end
            "
          >
            <action-button
              id="add-comment-cancel"
              :i="`fas fa-times-circle`"
              :responsive="true"
              variant="outline-danger m-1"
              @click="cancel()"
            >
              Cancelar
            </action-button>
            <action-button
              id="add-comment-save"
              i="fas fa-save"
              :responsive="true"
              variant="success m-1"
              @click="save()"
            >
              Guardar
            </action-button>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import IconView from 'icon-view';
import CustomDropDown from 'custom-drop-down';
import CustomTextArea from 'CustomTextArea';
import ActionButton from 'ActionButton';
import alert from '@/mixins/alert.mixin';

import { getPeiSection, getPeiSubSection } from '@/api/code.api';
import { saveComment } from '@/api/comment.api';

export default {
  name: 'AddComment',
  components: {
    IconView,
    CustomDropDown,
    CustomTextArea,
    ActionButton,
  },
  mixins: [alert],
  props: {
    studentSieId: {
      type: [String, Number],
      required: true,
    },
    peiHistory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comment: {
        studentSieId: Number(this.studentSieId),
        peiId: this.peiHistory.peiId,
        academicYearId: this.peiHistory.academicYearId,
        sectionId: null,
        subSectionId: null,
        description: '',
        peiPart: null,
        category: null,
      },
      availablePeiParts: [],
      availableCategories: [],
    };
  },
  mounted() {
    getPeiSection().then(({ data }) => (this.availablePeiParts = data));
  },
  methods: {
    async onPeiPartChange(value) {
      if (value?.id) {
        this.comment.sectionId = value.id;
        await getPeiSubSection(value.id).then(
          ({ data }) => (this.availableCategories = data)
        );
      } else {
        this.comment.peiPartId = null;
      }
    },
    onCategoryChange(value) {
      if (value?.id) {
        this.comment.subSectionId = value.id;
      } else {
        this.comment.subSectionId = null;
      }
    },
    async save() {
      const isValid = await this.$refs.addCommentRef.validate();
      if (isValid) {
        saveComment(this.comment)
          .then(() => {
            this.ShowToast(
              'Alerta',
              'El comentario ha sido añadido exitosamente.',
              'success',
              5000
            );
            this.$emit('on-save', this.comment);
          })
          .catch((err) => {
            if (err.response != null)
              this.ShowToast(
                'Alerta',
                err.response.data.message || 'error',
                'error',
                5000
              );
          });
      }
    },
    cancel() {
      this.$emit('on-cancel');
    },
  },
};
</script>
