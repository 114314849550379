<template>
  <div class="row">
    <div class="col-12">
      <custom-result-not-found v-if="commentsHistory.length === 0" />
      <UiTimeLine v-else>
        <UiTimeLineItem v-for="(item, index) in commentsHistory" :key="index">
          <template #date>
            <div class="mt-2 pl-2">
              <span class="font-weight-bold">
                {{ item.createdDate | moment('DD/MMM/YYYY') }}
              </span>
              <span class="font-14">
                {{ item.createdDate | moment('hh:mm A') }}
              </span>
            </div>
          </template>
          <template #icon>
            <div class="mt-2">
              <i class="fa-stack icon-circle">
                <i
                  class="
                    fas
                    fa-circle fa-stack-2x
                    icon-circle-stack
                    text-gray-light
                  "
                />
                <i class="fas fa-signature fa-stack-1x fa-inverse pt-1" />
              </i>
            </div>
          </template>
          <div class="row">
            <div class="col-md-2 pt-2">
              <div class="font-16 font-weight-bold">
                {{ item.section }}
              </div>
              <div v-if="item.subSection" class="font-14">
                {{ item.subSection }}
              </div>
            </div>
            <div class="col-md-10 pl-5">
              <div class="row">
                <div class="col-md-9 pb-3 border-right border-2">
                  <UiDetailDisplay
                    label="Comentario:"
                    :text="item.description"
                  />
                </div>

                <div class="col-md-3 pl-4">
                  <UiDetailDisplay
                    label="Comentado por:"
                    :text="item.createdBy"
                  />
                </div>
              </div>
            </div>
          </div>
        </UiTimeLineItem>
      </UiTimeLine>
    </div>
  </div>
</template>

<script>
import UiTimeLine from '@/components/ui/UiTimeLine';
import UiTimeLineItem from '@/components/ui/UiTimeLineItem';
import UiDetailDisplay from '@/components/ui/UiDetailDisplay';
import CustomResultNotFound from 'custom-result-not-found';
import alert from '@/mixins/alert.mixin';

import { getCommentHistory as _getCommentHistory } from '@/api/comment.api';

export default {
  name: 'CommentHistory',
  mixins: [alert],
  components: {
    UiTimeLine,
    UiTimeLineItem,
    UiDetailDisplay,
    CustomResultNotFound,
  },
  props: {
    studentSieId: {
      type: [String, Number],
      required: true,
    },
    academicYearId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      commentsHistory: [],
    };
  },
  mounted() {
    this.getCommentHistory();
  },
  methods: {
    async getCommentHistory() {
      _getCommentHistory(Number(this.studentSieId), this.academicYearId)
        .then(({ data }) => (this.commentsHistory = data))
        .catch((err) => {
          if (err.response != null)
            this.ShowToast(
              'Alerta',
              err.response.data.message || 'error',
              'error',
              5000
            );
        });
    },
  },
};
</script>

<style scoped>
.icon-circle {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  line-height: 1.8em !important;
}

.icon-circle-stack {
  font-size: 32px;
  position: absolute;
  top: 3px;
  left: 0px;
}
.border-2 {
  border-width: 2px !important;
}
</style>
