const DocumentTypes = {
  MinuteReferralEvaluationAt: 13,
  MinutaDiscussionSigned: 16,
  EvaluationReportSigned: 17,
  Minuta: 20,
  Otro: 21,
  CopiaDeQuerella: 22,
  ReferralServiceAT: 23,
  PreviousEvaluationAT: 24,
  LocationRejectedDocument: 26,
  MinuteControversy: 28,
  MinutaResolucionControversia: 29,
  CertTarea15ManualEEMadrePadre: 210,
  PropuestaServicios: 209,

  //Intervetions Plans
  PlanDeIntervencion: 83,
  PaquetePlanAnual: 84,
  PlanDeEnmienda: 85,
  ResumenResultadoDeServicios: 86, //No existe :(
  PaymentCertificate: 1541,
  EvidencePaymentReceipt: 1542,
  Other: 1543,
  ReimbursementCertification: 1550,
};

export default DocumentTypes;
