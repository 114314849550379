<template>
  <div v-if="studentSieId" id="student-pei-history">
    <MatchMedia v-slot="{ mobile }">
      <b-row class="mb-4">
        <b-col cols="12" md="6" />
        <b-col cols="12" md="6">
          <b-card class="bg-gray border-0">
            <b-row>
              <b-col cols="12" md="8">
                <v-select
                  v-if="showYearsFilter"
                  class="peiHistoryDropdown w-100 mr-3"
                  :clearable="false"
                  label="description"
                  :options="academicYearsList"
                  :reduce="(year) => year.year"
                  :searchable="true"
                  :value="selectedAcademicYear"
                  @input="setSelected"
                />
              </b-col>
              <b-col class="mt-4 mt-md-0" cols="12" md="4">
                <b-button block variant="success" @click="onSearch">
                  <em class="fas fa-search fa-fw" />
                  Buscar
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <CustomTitleDivider class="my-4" text="Historial del Estudiante" />

      <custom-callout
        v-for="(item, i) in peiHistoryData"
        :key="i"
        :ref="'student-history-callout-' + i"
        class-main="mt-3 rounded"
        :has-toggle="true"
        :opened="i === activeIndex"
      >
        <div class="row py-1">
          <div class="col-12 col-md-1 mr-3">
            <div class="d-flex align-items-center h-100">
              <UiBadge
                :color-hex="getStatusColor(item)"
                :text="item.peiStatusDescription"
                text-class="text-dark text-wrap"
              />
            </div>
          </div>
          <div class="col-md-8 border-left">
            <div class="row mb-3">
              <div class="col-12 col-md-2 mt-4 mt-md-0 ml-5">
                <UiDetailDisplay
                  label="Año Académico:"
                  :text="item.academicYear"
                />
              </div>
              <div class="col-12 col-md-2 mt-4 mt-md-0 ml-5">
                <UiDetailDisplay
                  label="Tipo de Solicitud:"
                  :text="item.peiTypeDesc"
                />
              </div>
              <div class="col-12 col-md-2 mt-4 mt-md-0 ml-5">
                <UiDetailDisplay label="Número:" :text="item.peiIdFormatted" />
              </div>
              <div class="col-12 col-md-1 mt-4 mt-md-0 ml-5">
                <UiDetailDisplay
                  label="Enmienda:"
                  :text="item.generalRevisionNo"
                />
              </div>
              <div class="col-12 col-md-2 mt-4 mt-md-0 ml-5">
                <UiDetailDisplay label="Fecha:">
                  <template #default="{ placeholder }">
                    <span v-if="item.generalClosedDate">
                      {{ item.generalClosedDate | moment('DD/MMM/YYYY') }}
                    </span>
                    <span v-else>
                      {{ placeholder }}
                    </span>
                  </template>
                </UiDetailDisplay>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2 mt-4 mt-md-0">
            <div class="d-flex align-items-center justify-content-end h-100">
              <DropDown
                :class="{ 'w-100': mobile }"
                :items="getMenuItems(item, i)"
                normal-size
                @getAtServiceReport="getAtServiceReport"
                @getInd13PeiReport="getInd13PeiReport"
                @getInd7PeiReport="getInd7PeiReport"
                @getReferralPeiReport="getReferralPeiReport"
                @getReportsPEIPS="getReportsPEIPS"
                @getTransportationRequestReport="getTransportationRequestReport"
                @getTransportationRequestReportAEE="
                  getTransportationRequestReportAEE
                "
                @goToControversy="goToControversy(item)"
                @openAddComment="openTab(i, tabItems.AddComment)"
                @openCommentHistory="openTab(i, tabItems.CommentHistory)"
              />
            </div>
          </div>
        </div>
        <template #edition>
          <div class="additional-container">
            <div class="p2 mt-1">
              <div class="bg-gray p-2">
                <div class="bg-white p-3 rounded pl-c-xl">
                  <custom-tab
                    content-class="mt-3"
                    :default-active-tab="activeTab"
                    nav-class="nav-vue-underline"
                    :no-nav-style="true"
                  >
                    <custom-tab-item
                      :active="activeTab === tabItems.CommentHistory"
                      class="mt-2"
                      title="Historial de Comentarios"
                    >
                      <comment-history
                        :academic-year-id="item.academicYearId"
                        :student-sie-id="studentSieId"
                      />
                    </custom-tab-item>
                    <custom-tab-item
                      :active="activeTab === tabItems.AddComment"
                      class="mt-2"
                      title="Añadir Comentario"
                    >
                      <add-comment
                        :pei-history="item"
                        :student-sie-id="studentSieId"
                        @on-cancel="closeTab(i)"
                        @on-save="closeTab(i)"
                      />
                    </custom-tab-item>
                  </custom-tab>
                </div>
              </div>
            </div>
          </div>
        </template>
      </custom-callout>
      <vue-good-table
        class="mt-4"
        :columns="tableConfig.columns"
        mode="remote"
        :pagination-options="{
          enabled: true,
        }"
        :rows="peiHistoryData"
        :sort-options="{
          enabled: false,
        }"
        :total-rows="tableConfig.totalRecords"
        @on-column-filter="onColumnFilter"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        @on-sort-change="onSortChange"
      >
        <template #emptystate>
          <span>
            <span>El estudiante no cuenta con PEI o PS en sistema</span>
            <span>
              {{
                selectedAcademicYear == 0 ? '.' : ' para el año seleccionado.'
              }}
            </span>
          </span>
        </template>

        <template slot="pagination-bottom" slot-scope="props">
          <customPager
            ref="historyPager"
            :page-changed="props.pageChanged"
            :per-page="tableConfig.serverParams.perPage"
            :per-page-changed="props.perPageChanged"
            :total="props.total"
          />
        </template>
      </vue-good-table>
    </MatchMedia>
  </div>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries';
import CustomTitleDivider from 'custom-separator';
import DropDown from 'dropdown';
import generalService from '@/services/pei/general';
import mixAlert from '@/mixins/alert.mixin';
import PeiStatus from '@/utils/constants/pei/peiStatus';
import reportService from '@/services/report';
import UiDetailDisplay from '@/components/ui/UiDetailDisplay';
import UiBadge from '@/components/ui/UiBadge';

import CustomCallout from 'custom-callout';
import CustomTab from 'custom-tabs/src/CustomTab';
import CustomTabItem from 'custom-tabs/src/CustomTabItem';

import AddComment from './student/AddComment';
import CommentHistory from './student/CommentHistory.vue';
import DocumentsService from '@/services/documents';

const TAB_ITEMS = {
  CommentHistory: 0,
  AddComment: 1,
};

export default {
  name: 'StudentPeiHistory',
  mixins: [mixAlert],
  components: {
    DropDown,
    CustomTitleDivider,
    UiDetailDisplay,
    UiBadge,
    MatchMedia,
    CustomCallout,
    CustomTab,
    CustomTabItem,
    AddComment,
    CommentHistory,
  },
  props: {
    showActionsColumn: { type: Boolean, default: true },
    showYearsFilter: { type: Boolean, default: true },
    studentSieId: { type: [String, Number], default: null },
  },
  data() {
    return {
      peiId: null,
      peiHistoryData: [],
      academicYearsList: [],
      selectedAcademicYear: '0',
      activeTab: TAB_ITEMS.CommentHistory,
      activeIndex: null,
      tableConfig: {
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: {
            field: '',
            type: '',
          },
          page: 1,
          perPage: 10,
        },
        columns: [],
      },
    };
  },
  computed: {
    tabItems() {
      return TAB_ITEMS;
    },
  },
  methods: {
    getMenuItems(item, index) {
      const invalidStatuses = [ PeiStatus.Cancelado, PeiStatus.Incompleto ];
      const validStatus = !invalidStatuses.some(id => id === item.peiStatusId);

      let items = [
        {
          name: 'Cuestionario del PEI en Controversia',
          icon: 'fas fa-clipboard-list-check text-green',
          visible: item.peiControversyFlag,
          border: 'bottom',
          click: () =>
            this.$router.push({
              name: 'student-pei-controversy',
              params: { peiId: item.peiId },
            }),
        },
        {
          label: 'Reporte del PEI o PS',
          icon: 'fas fa-print text-green',
          visible: validStatus && !item.canDownloadFromStorage,
          click: () => this.getReportsPEIPS(item),
        },
        {
          name: 'Imprimir PEI o PS Firmado',
          icon: 'fas fa-print text-green',
          visible: item.canDownloadFromStorage,
          click: () => this.getReportsPEIPSFromStorage(item),
        },
        {
          name: 'Imprimir de Referidos del PEI o PS',
          icon: 'fas fa-print text-green',
          EmitGo: 'getReportsPEIPS',
          visible: item.peiReferralFlag,
          click: () => this.getReferralPeiReport(item),
        },
        {
          name: 'Reporte de Guía de AT',
          icon: 'fas fa-print text-green',
          visible: this.verifyPeiStatusForAtService(item, item.atServiceFlag),
          click: () => this.getAtServiceReport(item),
        },
        {
          name: 'Historial de Comentarios',
          EmitGo: 'openCommentHistory',
          icon: 'fas fa-file-alt text-green',
          click: () => this.openTab(index, this.tabItems.CommentHistory),
        },
        {
          name: 'Añadir Comentario',
          EmitGo: 'openAddComment',
          icon: 'fas fa-comment-alt text-green',
          click: () => this.openTab(index, this.tabItems.AddComment),
        },
        {
          name: 'Ver Resumen de Resultados',
          EmitGo: 'getResultSummary',
          icon: 'fas fa-copy text-green',
          click: () => this.getResultSummaryReport(item),
        },
      ];
      return items;
    },
    getReportsPEIPSFromStorage(item) {
      if (item.signedPeiAttachmentId) {
        return generalService.downloadDocumentByAttachmentId(
          item.signedPeiAttachmentId
        );
      } else {
        this.getReportsPEIPS(item);
      }
    },
    getReportsPEIPS(item) {
      let peiId = item.peiIdFormatted.replace('-', '');
      let self = this;

      let promise = new Promise((resolve) => {
        self
          .$ApiGet('/api/report/pei/' + peiId)
          .then((res) => {
            self.report = res.data;
            generalService.documentDownloadReport(
              self.report.content,
              self.report.filename
            );
            resolve(res.data);
          })
          .catch((err) => {
            self.response = err;
          });
      });
      return promise;
    },
    async getReferralPeiReport(item) {
      let peiId = item.peiIdFormatted.replace('-', '');
      let self = this;

      let promise = new Promise((resolve) => {
        self
          .$ApiGet('/api/report/pei/referralreport/' + peiId)
          .then((res) => {
            self.report = res.data;
            generalService.documentDownloadReport(
              self.report.content,
              self.report.filename
            );
            resolve(res.data);
          })
          .catch((err) => {
            self.response = err;
          });
      });
      return promise;
    },
    async getInd13PeiReport(item) {
      let peiId = item.peiIdFormatted.replace('-', '');
      let self = this;

      let promise = new Promise((resolve) => {
        self
          .$ApiGet('/api/report/pei/ind13report/' + peiId)
          .then((res) => {
            self.report = res.data;
            generalService.documentDownloadReport(
              self.report.content,
              self.report.filename
            );
            resolve(res.data);
          })
          .catch((err) => {
            self.response = err;
          });
      });
      return promise;
    },
    async getInd7PeiReport(item) {
      let peiId = item.peiIdFormatted.replace('-', '');
      let self = this;

      let promise = new Promise((resolve) => {
        self
          .$ApiGet('/api/report/pei/ind7report/' + peiId)
          .then((res) => {
            self.report = res.data;
            generalService.documentDownloadReport(
              self.report.content,
              self.report.filename
            );
            resolve(res.data);
          })
          .catch((err) => {
            self.response = err;
          });
      });
      return promise;
    },

    async getAtServiceReport(peiId) {
      let self = this;

      let promise = new Promise((resolve) => {
        self
          .$ApiGet('/api/report/atservice/' + peiId.peiIdFormatted)
          .then((res) => {
            self.report = res.data;
            if (self.report.content == null) {
              this.ShowToast('', 'El reporte no pudo ser generado', 'error');
              return;
            }
            generalService.documentDownloadReport(
              self.report.content,
              self.report.filename
            );
            resolve(res.data);
          })
          .catch((err) => {
            self.response = err;
          });
      });
      return promise;
    },
    async getTransportationRequestReport(item) {
      const peiId = item.peiIdFormatted.replace('-', '');
      const analysisServiceId = 0;

      await reportService.downloadTransportation(peiId, analysisServiceId);
    },
    async getTransportationRequestReportAEE(item) {
      const peiId = item.peiIdFormatted.replace('-', '');
      const analysisServiceId = 0;

      await reportService.downloadTransportationAEE(peiId, analysisServiceId);
    },
    async getResultSummaryReport(item) {
      const peiId = item.peiIdFormatted.replace('-', '');

      reportService
        .getPeiResultSummaryDocument(peiId)
        .then((resp) => {
          if (resp?.data) {
            DocumentsService.Download(resp.data);
          } else {
            this.ShowToast('', 'No cuenta con resumen de resultados.', 'error');
          }
        })
        .catch((err) => {
          this.ShowToast(
            'Alerta',
            err.response.data.message || 'error',
            'error'
          );
        });
    },
    getReportsPEIPSReferido(peiID) {
      return peiID;
    },
    UpdateReportsPEIPS(peiID) {
      return peiID;
    },

    verifyPeiUpdateReportAccess(peiObj) {
      const status = [
        PeiStatus.Completado,
        PeiStatus.Firmado,
        PeiStatus.SignedWithControversy,
      ];
      return (
        status.findIndex((x) => x == peiObj.peiStatusId) >= 0 &&
        peiObj.peiTypeDesc.toLowerCase() == 'pei'
      );
    },
    verifyPeiStatusForAtService(peiObj, peiAtServiceFlag) {
      return peiObj.peiStatusId == PeiStatus.Firmado && peiAtServiceFlag;
    },
    verifyTransportationRequestPermission(peiObj, transportationReportFlag) {
      return (
        (peiObj.peiStatusId == PeiStatus.Firmado ||
          peiObj.peiStatusId == PeiStatus.PartialSignedPendingFunds ||
          peiObj.peiStatusId == PeiStatus.SignedWithControversy) &&
        transportationReportFlag
      );
    },
    verifyPeiPSReportAccess(peiObj) {
      return (
        peiObj.peiStatusId != PeiStatus.Cancelado &&
        peiObj.peiStatusId != PeiStatus.Incompleto
      );
    },
    verifyControversyQuestionnaireAccess(peiObj) {
      return peiObj.peiStatusId == PeiStatus.SignedWithControversy;
    },
    getStudentPEI() {
      if (this.studentSieId) {
        this.$ApiGet(
          '/api/pei/history?studentSieId=' +
            this.studentSieId +
            '&year=' +
            this.selectedAcademicYear +
            '&pageNumber=' +
            this.tableConfig.serverParams.page +
            '&pageSize=' +
            this.tableConfig.serverParams.perPage
        )
          .then((res) => {
            this.tableConfig.totalPages = res.data.pageCount;
            this.peiHistoryData = res.data.data
              ? this.formatHistoryData(res.data.data)
              : [];
            this.tableConfig.totalRecords = res.data.totalItemCount;
          })
          .catch((err) => {
            this.response = err;
          });
      }
    },
    formatHistoryData(data) {
      return data.map((p) => {
        if (p.generalClosedDate != null)
          p.generalClosedDate = p.generalClosedDate.slice(0, 10); //ECG:Modifico fecha en string para asegurar que todas tengan el mismo formato, algunas me llagaban sin fraccion de segundos

        p.peiId = p.peiIdFormatted;
        p.peiIdFormatted =
          p.peiIdFormatted.slice(0, 4) + '-' + p.peiIdFormatted.slice(4, 10);
        p.generalRevisionNo = p.generalRevisionNo.toString().padStart(2, '0');
        p.peiTypeDesc = p.unilateralFlag === '0' ? 'PEI' : 'PS';
        return p;
      });
    },
    changeAcademicYear(event) {
      this.selectedAcademicYear =
        event.target.options[event.target.options.selectedIndex].value;
      this.getStudentPEI();
    },
    getAcademicYear() {
      this.$ApiGet('/api/academicyear')
        .then((res) => {
          this.academicYearsList = res.data || [];
          this.academicYearsList.splice(0, 0, {
            id: 0,
            description: 'Año Académico',
            year: '0',
          });
        })
        .catch((err) => {
          this.response = err;
        });
    },
    setSelected(value) {
      this.selectedAcademicYear = value;
    },
    onSearch() {
      if (this.selectedAcademicYear) {
        this.updateParams({ page: 1 });
        this.getStudentPEI();
        this.$refs['historyPager'].reset();
      }
    },

    //ECG:Table methods
    updateParams(newProps) {
      this.tableConfig.serverParams = Object.assign(
        {},
        this.tableConfig.serverParams,
        newProps
      );
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getStudentPEI();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getStudentPEI();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.getStudentPEI();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.getStudentPEI();
    },

    getStatusColor(row) {
      let borderClass = '';
      switch (row.peiStatusDescription) {
        case 'Borrador':
          borderClass = '#fcc9c8';
          break;
        case 'Cancelado':
          borderClass = '#d5b89a';
          break;
        case 'Completado':
          borderClass = '#f8ea80';
          break;
        case 'Firmado':
          borderClass = '#a3e5b3';
          break;
        case 'Incompleto':
          borderClass = '#d5b89a';
          break;
        case 'Completado – Pendiente Fondos':
          borderClass = '#a7e6dc';
          break;
        case 'Firmado Parcial - Pendiente de Fondos':
        case 'Firmado - Controversia':
          borderClass = '#a3e5b3';
          break;
        case 'Propuesta de PEI - Rechazada':
          borderClass = '#ddb3d7';
          break;
      }
      return borderClass;
    },
    openTab(index, tab) {
      this.$refs['student-history-callout-' + index][0].open();
      this.activeIndex = index;
      this.activeTab = tab;
    },
    closeTab(index) {
      this.$refs['student-history-callout-' + index][0].isOpen = false;
      this.activeTab = -1;
    },
  },
  created() {
    this.getStudentPEI();
    this.getAcademicYear();
  },
};
</script>

<style lang="scss" scoped>
#student-pei-history ::v-deep {
  .vs__dropdown-toggle {
    background: white !important;
  }

  #dropdownMenuButton {
    background: transparent;
  }
}
</style>
